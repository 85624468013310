<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @datachange="getList"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="WO_TYPE"
            type="none"
            itemText="codeName"
            itemValue="code"
            name="woType"
            label="정비/점검/PM 구분"
            v-model="searchParam.woType"
            @datachange="getList"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="정비/점검 부서" name="workDeptCd" v-model="searchParam.workDeptCd" @datachange="getList" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="margin-top:10px;">
          <q-btn-group flat class="col-3">
            <q-btn color="grey-8" class="calMoveBtn" outline rounded icon="arrow_back_ios" label="이전달" @click="onPrev" />
            <q-btn color="grey-8" class="calMoveBtn" outline rounded :label="title" style="width: 200px" @click="onToday"/>
            <q-btn color="grey-8" class="calMoveBtn" outline rounded icon-right="arrow_forward_ios" label="다음달" @click="onNext" />
          </q-btn-group>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom:10px;">
          <font v-show="searchParam.woType==='WT00000001'" style="font-size:0.9em;font-weight:600;">
            <q-chip color="orange" /> W/O발행 및 계획 &nbsp;&nbsp;
            <q-chip color="blue" /> W/O승인중 &nbsp;&nbsp;
            <q-chip color="deep-purple-6" /> 작업중 &nbsp;&nbsp;
            <q-chip color="yellow" /> 작업 승인중 &nbsp;&nbsp;
            <q-chip color="green" /> W/O완료 &nbsp;&nbsp;
            <q-chip color="red-6" /> W/O반려 &nbsp;&nbsp;
          </font>
          <font v-show="searchParam.woType==='WT00000002'" style="font-size:0.9em;font-weight:600;">
            <q-chip color="orange" /> 계획수립 &nbsp;&nbsp;
            <q-chip color="deep-purple-6" /> 점검중 &nbsp;&nbsp;
            <q-chip color="green" /> 점검완료 &nbsp;&nbsp;
            <q-chip color="red-6" /> 지연 &nbsp;&nbsp;
          </font>
          <font v-show="searchParam.woType==='WT00000003'" style="font-size:0.9em;font-weight:600;">
            <q-chip color="orange" /> W/O발행 및 계획 &nbsp;&nbsp;
            <q-chip color="blue" /> W/O승인중 &nbsp;&nbsp;
            <q-chip color="deep-purple-6" /> 작업중 &nbsp;&nbsp;
            <q-chip color="yellow" /> 작업 승인중 &nbsp;&nbsp;
            <q-chip color="green" /> W/O완료 &nbsp;&nbsp;
            <q-chip color="red-6" /> W/O반려 &nbsp;&nbsp;
            <q-chip color="grey-6" /> 차기정비예정/오더작성예정 &nbsp;&nbsp;
          </font>
        </div>
      </template>
    </c-search-box>
    <q-calendar
      ref="calendar"
      v-model="selectedDate"
      view="month"
      locale="ko-kr"
      :day-height="dayHeight"
      :day-min-height="dayMinHeight"
      @change="onChange"
      show-work-weeks
    >
      <template #week="{ week }">
        <template>
          <q-badge
            v-for="(computedEvent, index) in getWeekEvents(week)"
            :key="index"
            class="q-row-event"
            :class="badgeClasses(computedEvent, 'day')"
            :style="badgeStyles(computedEvent, 'day', week.length)"
            @click="openJobDetail(computedEvent)"
          >
            <template v-if="computedEvent.event">
              <q-icon :name="computedEvent.event.icon" class="q-mr-xs"></q-icon>
              <span class="ellipsis">{{ computedEvent.event.title }}</span>
            </template>
            <template v-if="computedEvent.event && computedEvent.event.type !== 'D'">
              <q-tooltip>정비오더 발행부서: {{computedEvent.event.workDeptName}}<br>설비: {{computedEvent.event.equipmentName}}<br>{{computedEvent.event.woWorkPlanStepName}}</q-tooltip>
            </template>
          </q-badge>
        </template>
      </template>
    </q-calendar>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import QCalendarm from '@quasar/quasar-ui-qcalendar'
import {QCalendar} from '@quasar/quasar-ui-qcalendar'
import "@quasar/quasar-ui-qcalendar/dist/index.css"
export default {
  name: 'outernal-manage',
  components: {
    QCalendar,
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        plantDts: [],
        workDeptCd: '',
        woType: 'WT00000001',
      },
      popupOptions: {
        isFull: true,
        suffixChip: '',
        target: null,
        title: '',
        width: '80%',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      listUrl2: '',
      listUrl3: '',
      title: '',
      dateFormatter: undefined,
      start: undefined,
      selectedDate: '',
      events: [],
      monthDaysFirst: [],
      monthDaysLast: [],
      dayHeight: 140,
      dayMinHeight: 140,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.wod.workorder.plan.list.url + '/calendar';
      // this.listUrl = selectConfig.wod.oldworkorder.plan.list.url + '/calendar';
      this.listUrl2 = selectConfig.sop.min.equipment.plan.list.url;
      this.listUrl3 = selectConfig.pm.mst.list.url + '/calendar';
    },
    getList() {
      // day height : 120
      // one line : 20
      if (this.$refs.calendar !== undefined) {
        this.searchParam.plantDts = [this.$refs.calendar.lastStart,this.$refs.calendar.lastEnd];
      } else {
        this.searchParam.plantDts = [this.start.year + '-' + this.start.month + '-01',this.start.year + '-' + this.start.month + '-31'];
      }
      this.events = [];
      if (this.searchParam.woType == 'WT00000001') {
        this.getSearch('A')
      } else if (this.searchParam.woType == 'WT00000002') {
        this.getSearch('B')
      } else if (this.searchParam.woType == 'WT00000003') {
        this.getSearch('C')
      }
    },
    getSearch(_type) {
      if (_type == 'A') {
        // 작업 조회
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            this.events.push({
              type: 'A',
              title: '[' + _item.woWorkTypeName + '] ' +  _item.title,
              start: _item.start,
              end: _item.end,
              color: this.getColors(_item.woWorkPlanStepCd),
              icon: this.getIcons(_item.woWorkPlanStepCd),
              workPlanId: _item.workPlanId,
              plantCd: _item.plantCd,
              woWorkPlanStepCd: _item.woWorkPlanStepCd,
              woWorkPlanStepName: _item.woWorkPlanStepName,
              woRequestId: _item.woRequestId,
              workDeptName: _item.workDeptName,
              equipmentName: _item.equipmentName,
              copyFlag: _item.copyFlag,
            });
          })
        },);
      } else if (_type == 'B') {
        // 예방점검 조회
        this.$http.url = this.listUrl2;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            this.events.push({
              type: 'B',
              title: _item.equipmentName,
              start: (_item.checkStatusCd == 'MCSC000015' ? _item.checkDate : _item.checkScheduleDate) ,
              end: (_item.checkStatusCd == 'MCSC000015' ? _item.checkDate : _item.checkScheduleDate) ,
              color: this.getColors(_item.checkStatusCd),
              icon: this.getIcons(_item.checkStatusCd),
              workPlanId: _item.minEquipmentCheckId,
              plantCd: _item.plantCd,
              woWorkPlanStepCd: _item.checkStatusCd,
              woWorkPlanStepName: _item.checkStatusName,
              woRequestId: _item.checkResultCd,
              workDeptName: _item.checkDeptName,
              equipmentName: _item.equipmentCdName,
              copyFlag: 'N',
            });
          })
        },);
      } else if (_type == 'C') {
        // 예방점검 조회
        this.$http.url = this.listUrl3;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          if (_result.data.calendarList && _result.data.calendarList.length > 0) {
            this.$_.forEach(_result.data.calendarList, pmData => {
              if (this.$refs.calendar.lastStart <= pmData.pmNextDt && pmData.pmNextDt <= this.$refs.calendar.lastEnd) {
                this.events.push({
                  type: 'D',
                  title: pmData.pmMstName + ' [차기정비예정]',
                  start: pmData.pmNextDt,
                  end: pmData.pmNextDt,
                  color: 'grey-6',
                  icon: 'group_add',
                });
              }
              if (this.$refs.calendar.lastStart <= pmData.pmOrderDate && pmData.pmOrderDate <= this.$refs.calendar.lastEnd) {
                this.events.push({
                  type: 'D',
                  title: pmData.pmMstName + ' [차기오더작성예정]',
                  start: pmData.pmOrderDate,
                  end: pmData.pmOrderDate,
                  color: 'grey-6',
                  icon: 'group_add',
                });
              }
            })
          }
          if (_result.data.workOrderList && _result.data.workOrderList.length > 0) {
            this.$_.forEach(_result.data.workOrderList, _item => {
              this.events.push({
                type: 'C',
                title: '[' + _item.woWorkTypeName + '] ' +  _item.title,
                start: _item.start,
                end: _item.end,
                color: this.getColors(_item.woWorkPlanStepCd),
                icon: this.getIcons(_item.woWorkPlanStepCd),
                workPlanId: _item.workPlanId,
                plantCd: _item.plantCd,
                woWorkPlanStepCd: _item.woWorkPlanStepCd,
                woWorkPlanStepName: _item.woWorkPlanStepName,
                woRequestId: _item.woRequestId,
                workDeptName: _item.workDeptName,
                equipmentName: _item.equipmentName,
                copyFlag: _item.copyFlag,
              });
            })
          }
        },);
      }
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWPSC00000': // 정비요청
          returnColor = 'grey-6';
          break;
        case 'WWPSC00001': // 작업계획중
          returnColor = 'orange';
          break;
        case 'WWPSC00002': // 작업계획 승인중
          returnColor = 'blue';
          break;
        case 'WWPSC00004': // 작업결과 승인중
          returnColor = 'yellow';
          break;
        case 'WWPSC00003': // 작업진행중
          returnColor = 'deep-purple-6';
          break;
        case 'WWPSC00009': // 작업완료
          returnColor = 'green';
          break;
        case 'WWPSC99999': // 작업취소
        case 'WWPSC99998': // 작업계획취소
          returnColor = 'red-6';
          break;
        case 'MCSC000005': // 예방점검 계획수립
          returnColor = 'orange';
          break;
        case 'MCSC000010': // 점검중
          returnColor = 'deep-purple-6';
          break;
        case 'MCSC000015': // 점검완료
          returnColor = 'green';
          break;
        case 'MCSC000020': // 지연
          returnColor = 'red-6';
          break;
      }

      return returnColor;
    },
    getIcons(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWPSC00000': // 정비요청
          returnColor = 'border_color';
          break;
        case 'WWPSC00001': // 작업계획중
          returnColor = 'format_list_bulleted';
          break;
        case 'WWPSC00002': // 작업계획 승인중
          returnColor = 'thumb_up_off_alt';
          break;
        case 'WWPSC00003': // 작업진행중
          returnColor = 'manage_accounts';
          break;
        case 'WWPSC00009': // 작업완료
          returnColor = 'task_alt';
          break;
        case 'WWPSC99999': // 작업취소
        case 'WWPSC99998': // 작업계획취소
          returnColor = 'event_busy';
          break;
        case 'MCSC000005': // 예방점검 계획수립
          returnColor = 'format_list_bulleted';
          break;
        case 'MCSC000010': // 점검중
          returnColor = 'manage_accounts';
          break;
        case 'MCSC000015': // 점검완료
          returnColor = 'task_alt';
          break;
        case 'MCSC000020': // 지연
          returnColor = 'event_busy';
          break;
      }
      return returnColor;
    },
    onChange ({ start }) {
      this.start = start
      this.updateTitle()
      this.getList();
    },
    onPrev () {
      this.$refs.calendar.prev()
    },
    onNext () {
      this.$refs.calendar.next()
    },
    onToday() {
      this.$refs.calendar.moveToToday()
    },
    updateTitle () {
      this.title = this.start.year + '년 ' + this.start.month + '월'
    },
    isCssColor (color) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
    },
    badgeClasses (infoEvent, type) {
      const color = infoEvent.event !== undefined ? infoEvent.event.color : 'transparent'
      const cssColor = this.isCssColor(color)
      const isHeader = type === 'header'
      return {
        [`text-white bg-${color}`]: !cssColor,
        'full-width': !isHeader && (!infoEvent.side || infoEvent.side === 'full'),
        'left-side': !isHeader && infoEvent.side === 'left',
        'right-side': !isHeader && infoEvent.side === 'right',
        'cursor-pointer': infoEvent.event !== undefined && infoEvent.event.type !== 'D',
        'q-day-event-void': infoEvent.event === undefined
      }
    },
    badgeStyles (infoEvent, type, weekLength, timeStartPos, timeDurationHeight) {
      const s = {}
      if (timeStartPos) {
        s.top = timeStartPos(infoEvent.event.time) + 'px'
      }
      if (timeDurationHeight) {
        s.height = timeDurationHeight(infoEvent.event.duration) + 'px'
      }
      if (infoEvent.size !== undefined) {
        s.width = ((100 / weekLength) * infoEvent.size) + '% !important'
      }
      return s
    },
    getWeekEvents (week) {
      const tsFirstDay = QCalendarm.parsed(week[0].date + ' 00:00')
      const tsLastDay = QCalendarm.parsed(week[week.length - 1].date + ' 23:59')
      const firstDay = QCalendarm.getDayIdentifier(tsFirstDay)
      const lastDay = QCalendarm.getDayIdentifier(tsLastDay)

      const eventsWeek = []
      this.events.forEach((event, id) => {
        const tsStartDate = QCalendarm.parsed(event.start + ' 00:00')
        const tsEndDate = QCalendarm.parsed(event.end + ' 23:59')
        const startDate = QCalendarm.getDayIdentifier(tsStartDate)
        const endDate = QCalendarm.getDayIdentifier(tsEndDate)

        if (this.isBetweenDatesWeek(startDate, endDate, firstDay, lastDay)) {
          const left = QCalendarm.daysBetween(tsFirstDay, tsStartDate, true)
          const right = QCalendarm.daysBetween(tsEndDate, tsLastDay, true)

          eventsWeek.push({
            id, // index event
            left, // Position initial day [0-6]
            right, // Number days available
            size: week.length - (left + right), // Size current event (in days)
            event // Info
          })
        }
      })
      const events = []
      if (eventsWeek.length > 0) {
        const infoWeek = eventsWeek.sort((a, b) => a.left - b.left)
        infoWeek.forEach((event, i) => {
          this.insertEvent(events, week.length, infoWeek, i, 0, 0)
        })
      }
      
      // if (events.length <= 5) {
      //   resource.height = 120
      // } else if (events.length === 7) {
      //   resource.height = 140
      // } else if (events.length === 8) {
      //   resource.height = 168
      // } else if (events.length === 9) {
      //   resource.height = 189
      // } else if (events.length === 10) {
      //   resource.height = 210
      // } 
      return events
    },
    insertEvent (events, weekLength, infoWeek, index, availableDays, level) {
      const iEvent = infoWeek[index]
      if (iEvent !== undefined && iEvent.left >= availableDays) {
        if (iEvent.left - availableDays) {
          events.push({ size: iEvent.left - availableDays })
        }
        events.push({ size: iEvent.size, event: iEvent.event })
        if (level !== 0) {
          infoWeek.splice(index, 1)
        }
        const currentAvailableDays = iEvent.left + iEvent.size
        if (currentAvailableDays < weekLength) {
          const indexNextEvent = QCalendarm.indexOf(infoWeek, e => e.id !== iEvent.id && e.left >= currentAvailableDays)
          this.insertEvent(
            events,
            weekLength,
            infoWeek,
            indexNextEvent !== -1 ? indexNextEvent : index,
            currentAvailableDays,
            level + 1
          )
        }
      }
      else {
        events.push({ size: weekLength - availableDays })
      }
    },
    isBetweenDates (date, start, end) {
      return date >= start && date <= end
    },
    isBetweenDatesWeek (dateStart, dateEnd, weekStart, weekEnd) {
      return (
        (dateEnd < weekEnd && dateEnd >= weekStart) ||
          dateEnd === weekEnd ||
          (dateEnd > weekEnd && dateStart <= weekEnd)
      )
    },
    openJobDetail(row) {
      if (row.event.type == 'D') return;
      if (row.event.type == 'A' || row.event.type == 'C') {
        this.popupOptions.title = '정비오더 상세';
        this.popupOptions.suffixChip = row.event.woWorkPlanStepName
        this.popupOptions.param = {
          workPlanId: row.event.workPlanId,
          plantCd: row.event.plantCd,
          woWorkPlanStepCd: row.event.woWorkPlanStepCd,
          woRequestId: row.event.woRequestId,
          copyFlag: row.event ? row.event.copyFlag : 'N',
          firstCopy: false,
        };
        this.popupOptions.target = () => import(`${'@/pages/wod/plan/workOrderPlanDetail.vue'}`);
      } else {
        this.popupOptions.title = '설비점검결과 상세'; // 설비점검계획 상세
        this.popupOptions.param = {
          minEquipmentCheckId: row.event.workPlanId ? row.event.workPlanId : '',
          title: '설비점검결과',
          selectData: [],
          noPlan: 'N',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/mim/inspection/equipmentResultDetail.vue'}`);
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
